




























































import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import { ScreenText } from '@/lang/ScreenText';
import UIkit from 'uikit';

@Component({
  components: {
    ProgressButton
  }
})

export default class RemoveSessionAttendanceWarning extends Vue {
  screenText: ScreenText = new ScreenText();

  @PropSync('isRemoveAttendanceWarningVisible') isRemoveAttendanceWarningVisibleSync !: boolean;
  @Prop() sessionName !: string;

  @Watch('isRemoveAttendanceWarningVisible')
  onIsVisible(isRemoveAttendanceWarningVisible: boolean) {
    const isElementShowing = UIkit.modal(this.$el)
      ? UIkit.modal(this.$el).isToggled()
      : false;

    if (isRemoveAttendanceWarningVisible && isElementShowing === false) {
      UIkit.modal(this.$el).show();
    }
    else if (isRemoveAttendanceWarningVisible === false && isElementShowing) {
      UIkit.modal(this.$el).hide();
    }
  }

  get attendanceWarningHeaderAddon(): string {
    return this.screenText.getScreenText('SESSION_ATTENDANCE_WARNING_HEADER_ADDON');
  }

  get attendanceWarningSubtext(): string {
    return this.screenText.getScreenText('SESSION_ATTENDANCE_WARNING_SUBTEXT');
  }

  get attendanceWarningContinueConfirmation(): string {
    return this.screenText.getScreenText('SESSION_ATTENDANCE_WARNING_CONTINUE_CONFIRMATION');
  }

  get cancelButtonText(): string {
    return this.screenText.getScreenText('BTN_CANCEL_CAPS');
  }

  get saveButtonText(): string {
    return this.screenText.getScreenText('BTN_SAVE_CAPS');
  }


  closePopup() {
    this.isRemoveAttendanceWarningVisibleSync = false;
  }

  confirmAttendanceChange() {
    this.$emit('confirmAttendanceRemoval');
  }

}
